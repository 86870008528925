import React, { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { author } from '@Apis/auth';
import useCustomQuery from '@Utils/useCustomQuery';
import { formatNumber } from '@Utils/helpers';
import { list } from '@Apis/profile/bank-account';
import DropdownSelect from '@Components/DropdownSelect';
import ButtonSubmit from '@Components/ButtonSubmit';
import useCustomMutation from '@Utils/useCustomMuation';
import { save } from '@Apis/client/payment-request';
import { useNavigate } from 'react-router-dom';
import {toastError, toastSuccess} from '@Utils/toastrHelper';
import FieldInput from '../FieldInput';
import { useSelector } from 'react-redux';

function WithDraw(props) {
  const { refechDateHistory, account } = props;

  const [{ amount, errorMessage }, setAmount] = useState({});
  const [{ valueSelect, errorMessageSelect }, setValueSelect] = useState({});
  const { data: dataOptions } = useCustomQuery(list, { customer_id: account.id }, true);
  const { withdraw_available, can_withdraw_amount } = account || {};

  const navigate = useNavigate();
  const productType = useSelector((state) => state.ui.productType);

  const { mutate, isLoading } = useCustomMutation(save, true);

  const handleSetValue = useCallback((_, v) => {
    setValueSelect({ valueSelect: v });
  }, []);

  const handleChangeAmount = useCallback((v) => {
    setAmount({ amount: v });
  }, []);

  const handleSubmit = useCallback(() => {
    if (!amount && amount !== 0) {
      setAmount({ errorMessage: 'Vui lòng nhập số tiền rút ' });
    }
    if (!valueSelect) {
      setValueSelect({ errorMessageSelect: 'Vui lòng chọn tài khoản nhận tiền' });
    }
    if (amount && valueSelect) {
      mutate(
        {
          type: 'withdraw',
          bank_account_id: valueSelect,
          amount: Number(amount),
          customer_id: account.id,
          trade_type: productType,
          note: '',
        },
        {
          onSuccess: () => {
            navigate(`/${productType}/recharge?page=1`);
            setAmount({ amount: '' });
            setValueSelect({ valueSelect: '' });
            refechDateHistory();
            toastSuccess('Cập nhật yêu cầu thành công');
          },
          onError: () => {
            toastError(
              'Số tiền được không đủ để thực hiện rút, vui lòng kiểm tra lại các lệnh rút đang chờ duyệt.',
            );
          },
        },
      );
    }
  }, [amount, valueSelect]);

  return (
    <div>
      <div className="withdraw">
        <div className="preview-balance d-flex align-items-center">
          <div>
            <div style={{ fontSize: 14 }}>Số tiền được rút:</div>
            <div style={{ fontSize: 16, fontWeight: 600, marginTop: 5 }}>
              {formatNumber(can_withdraw_amount > 0 ? can_withdraw_amount : 0, '--')}
            </div>
          </div>
        </div>
        <div className="field-input-paymentwithdraw">
          <FieldInput
            min={0}
            max={Number(withdraw_available) || 0}
            onChange={handleChangeAmount}
            value={amount}
            placeholder="Nhập số tiền rút"
            required
            label="Nhập số tiền rút"
            type="number"
            disabled={(Number(withdraw_available) || 0) <= 0}
            errorMessage={errorMessage}
          />
        </div>
        <div
          className={`field-input-paymentwithdraw${
            errorMessageSelect ? ' field-dropdown-error' : ''
          }`}
        >
          <div className="label-field-input" style={{ marginBottom: 12 }}>
            Tài khoản nhận tiền <span style={{ color: '#FC3400' }}> *</span>
          </div>
          <DropdownSelect
            style={{ height: 45, display: 'flex', justifyContent: 'space-between', width: '100%' }}
            labelShow={false}
            setValueSelected={handleSetValue}
            valueSelected={valueSelect}
            options={
              dataOptions?.map((e) => ({
                value: e.id,
                label: `${e.account_name} - ${e.account_number}` || '--',
              })) || []
            }
          />
          <div style={{ color: '#FC3400', fontSize: 13, height: 12 }}>{errorMessageSelect}</div>
        </div>
      </div>
      <div className="d-flex justify-content-center" style={{ paddingTop: 16 }}>
        <ButtonSubmit
          onClick={handleSubmit}
          style={{ width: '100%', maxWidth: 200 }}
          styleText={{ fontSize: 14 }}
          name="Gửi yêu cầu"
          size="md"
          color="primary"
          disabled={(Number(withdraw_available) || 0) <= 0}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
}

WithDraw.propTypes = {
  refechDateHistory: PropTypes.func,
  account: PropTypes.any,
};

export default memo(WithDraw, (pre, n) => pre.refechDateHistory === n.refechDateHistory);
